import React from "react";
import secondSection from "../../../components/assets/img/pages/landing/cartoon-1.png";

const SecondSection = () => {
  return (
    <section className="second padding-7">                    
      <span>
        <h1 className="title blue-font text-center">Venha para o Gifthy</h1>
        <h3 className="subtitle yellow-font text-center">Onde o futuro é presente!</h3>
      </span>                    
      <div className="wrapper">
        <div className="left">
          <h2 className="subtitle white-font bold">Gifthy. <span className="yellow-font">É facil presentear.</span></h2>
          <p className="description yellow-font bold">Não deixe mais passar em branco datas importantes para seus amigos queridos.</p>
          <p className="description blue-font bold">Você não precisa mais se culpar por ter esquecido uma data importante ou por não ter acertado no presente.</p>
          <p className="description blue-font bold">Com o Gifthy você evita estes constrangimentos e ainda garante o melhor presente de modo fácil, rápido e seguro.</p>
        </div>
        <div className="right">
          <img className="image" src={secondSection} alt="cartoon" />
        </div>
      </div>
    </section>
  );
};

export default SecondSection;